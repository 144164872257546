<template>
  <a-modal
    v-model:visible="show"
    destroy-on-close
    width="600px"
    :mask-closable="false"
    @cancel="cancel"
    :title="title"
  >
    <a-form
      class="myform"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
      :rules="rules"
      :model="record"
      ref="formRef"
    >
      <a-form-item label="Nama Role" name="name">
        <a-input
          :disabled="readOnly || title === 'Ubah Role'"
          v-model:value="record.name"
        ></a-input>
      </a-form-item>
      <a-form-item label="Deskripsi">
        <a-input v-model:value="record.description" :disabled="readOnly"></a-input>
      </a-form-item>
      <a-form-item label="Level">
        <a-select :disabled="readOnly" v-model:value="record.level">
          <a-select-option v-for="(i, index) in [1, 2, 3, 4, 5, 6]" :key="index" :value="i">{{
            i
          }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="Status">
        <a-radio-group :disabled="readOnly" v-model:value="record.active">
          <a-radio :value="true">Aktif</a-radio>
          <a-radio :value="false">Non Aktif</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>

    <template #footer>
      <a-button key="submit" @click="submit" v-if="!readOnly" type="primary">Simpan</a-button>
      <a-button key="cancel" @click="show = false">Cancel</a-button>
    </template>
  </a-modal>
</template>

<script>
import { inject, toRefs, ref } from 'vue'

import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import { serialize } from 'object-to-formdata'
import rules from './rules'

export default {
  setup() {
    const show = inject('show')
    const showState = inject('showState')
    showState.record = Object.assign({}, showState.record)
    const refresh = inject('refresh')
    const formRef = ref()

    const submit = async e => {
      try {
        await formRef.value.validate()
      } catch (error) {
        console.error(error)
        return
      }

      if (showState.state === 'edit') {
        ubahRole(showState.record)
      }
      if (showState.state === 'new') tambahRole(showState.record)
    }
    const cancel = e => {
      show.value = false
    }

    const tambahRole = record => {
      Modal.confirm({
        title: 'Konfirmasi Tambah Role',
        content: 'Apakah anda ingin melanjutkan Tambah Role ?',
        onOk() {
          const form_data = serialize(record, { booleansAsIntegers: true })

          apiClient
            .post('/api/roles', form_data)
            .then(({ data }) => {
              show.value = false
              refresh()
            })
            .catch(e => message.error('Gagal menambah!'))
        },
        onCancel() {},
      })
    }

    const ubahRole = record => {
      Modal.confirm({
        title: 'Konfirmasi Ubah Role',
        content: 'Apakah anda ingin melanjutkan Ubah Role ?',
        onOk() {
          const form_data = serialize(record, { booleansAsIntegers: true })
          apiClient
            .post('/api/roles/' + record.id + '?_method=PUT', form_data)
            .then(({ data }) => {
              show.value = false
              refresh()
            })
            .catch(e => message.error('Gagal mengupdate!'))
        },
        onCancel() {},
      })
    }

    return { visible: true, show, submit, cancel, ...toRefs(showState), rules, formRef }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
